/* CSS สำหรับ custom-calendar-dropdown */
.custom-calendar-dropdown .ant-picker-panels {
    display: flex;
  }
  
  /* ปรับเป็นแนวนอนใน desktop */
  @media (min-width: 769px) {
    .custom-calendar-dropdown .ant-picker-panels {
      flex-direction: row; /* แนวนอนใน desktop */
    }
  }
  
  /* ปรับเป็นแนวตั้งใน mobile */
  @media (max-width: 768px) {
    .custom-calendar-dropdown {
      width: 100% !important;
      left: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .custom-calendar-dropdown .ant-picker-panels,
    .custom-calendar-dropdown .ant-picker-panel-container {
      flex-direction: column; /* แนวตั้งใน mobile */
    }
  }
  