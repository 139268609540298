@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.top-shadow {
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
  list-style-type: none;
}

.page-item {
  margin: 0 0.25rem;
}

.page-link {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  cursor: pointer;
}

.active .page-link {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}


div{
  @apply font-Kanit text-gray-700
}

.btn-primary3 {
  @apply py-2 px-4 w-full  bg-gray-400 text-white  rounded-xl shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75;
}

.btn-primary {
  @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-xl shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
}


.btn-primary2 {
  @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-sm shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ;
}

.input-primary {
  @apply py-2 px-4 bg-gray-200 text-gray-800 font-semibold rounded-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 w-20;
}

.input-primary2 {
  @apply py-2 px-4 bg-gray-200 text-gray-800 font-semibold rounded-sm shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 w-20;
}

.app-bar {
  @apply text-white font-semibold py-2 px-4 shadow-md align-middle justify-center flex space-x-20 h-20 items-center;
}

.font-text {
  @apply text-sm text-gray-800 font-medium space-x-5 justify-between flex;
}

.box-space {
  @apply space-x-1 flex justify-center items-center;
}

.sticky {
  /* transition: all 0.3s ease-in-out; */
}

.conten-left{
  @apply  flex py-7 px-4 m-0 justify-start items-center align-middle w-full h-full space-x-20;
}
.content{
  @apply w-9/12 py-1 px-2 mr-0 shadow-md align-middle justify-center items-center bg-white rounded-2xl focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-100;
}

.content2{
  @apply w-1/4 h-auto py-1 px-2 mr-0 shadow-md align-middle justify-center items-center bg-white rounded-2xl focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-100;
}
.content-out{
  @apply  bg-gray-100 flex py-7 px-4 m-0  justify-center items-center align-middle w-full h-full space-x-20;
}

.content-out{
  @apply bg-gray-100;
}

.space_bar{
  @apply  px-4 py-5 mr-4 ml-4 mt-4 mb-4 text-2xl font-semibold flex justify-between items-center border-l-inherit bg-white rounded-sm shadow-md focus:outline-none  focus:ring-opacity-75;
}

.space_bar_button{
  /* button color blue  */
  @apply py-2 px-4 w-auto mr-4 ml-4 mb-20 mt-20 text-center bg-blue-500 text-white font-semibold rounded-sm shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
}

.nice_grid {
  @apply  bg-white grid grid-cols-2 gap-4 mr-4 ml-4 mt-4 mb-4 px-4 py-5 w-1/2 rounded-sm shadow-md focus:outline-none  focus:ring-opacity-75 ; 
}

.nice_bar{
  @apply px-4 py-5 bg-gray-100 rounded-sm shadow-md focus:outline-none  focus:ring-opacity-75;  
}

.nice_button{
  @apply  bg-white grid grid-cols-2 gap-4 mr-4 ml-4 mt-4 mb-4 px-4 py-5 w-1/2 rounded-sm shadow-md focus:outline-none  focus:ring-opacity-75 ; 
}

/* rounded-sm shadow-md focus:outline-none  focus:ring-opacity-75 */

/* 
.app-bar2 {
  @apply text-white font-semibold py-2 px-4 shadow-md align-middle justify-center flex;
}


.content-label {
  @apply text-base text-gray-800 font-medium;
}



.conten-left{
  @apply  flex py-7 px-4 m-0  justify-start items-center align-middle w-full h-full space-x-20;
}

.content {
  @apply flex justify-evenly items-center align-middle w-full h-full space-x-20;
} */
